import React, { Component } from 'react';

class Auckland extends Component {
  render() {
    return (
      <div className="fm-max">
        <p className="fm-max-title">Flamingo Auckland</p>
        <p className="fm-max-text">To our Flamingo family,</p>
        <p className="fm-max-text">Unfortunately, Auckland Council decided our latest e-scooter license application was unsuccessful, therefore Flamingo will not be returning to streets of Auckland.</p>
        <p className="fm-max-text">We would like to thank every single person who has joined us on our journey over the last 14 months in Auckland. We have been overwhelmed by the support for a Kiwi owned e-scooter operator, from 50 scooters in June 2019 to 600+ scooters in June 2020 and over a quarter of a million rides.</p>
        <p className="fm-max-text">As a born and bred Kiwi company, we uphold our commitment to bringing the future of urban micro-mobility to New Zealand. We feel proud and excited to be operating in Wellington, our hometown, and also in Christchurch as the only locally-owned and operated e-scooter company in these markets.</p>
        <p className="fm-max-text">We hope to see you all again in the future. Until then, stay safe and look after one another!</p>
        <p className="fm-max-end">With love,<br/>The Flamingo Team</p>
      </div>
    );
  }
}

export default Auckland;
